<template>
  <div class="container">
    <div class="menu">
      <div class="menu-title">
        <p>领导关怀</p>
      </div>
    </div>
    <div class="right-content">
      <ul class="content-list">
        <li class="list-item" v-for="(item,index) in newsArr" :key="index">
          <div class="img-box">
            <img class="imgCover" :src="item.article_img" alt="">
          </div>
          <div class="item-content">
            <div class="title">
              {{item.article_title}}
            </div>
            <div class="time">
              {{item.date}}
            </div>
            <p>
              {{item.article_info}}
            </p>
          </div>
        </li>
      </ul>

      <div class="page">
        <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>

import { article_list } from '@/api/server';

export default {
  data () {
    return {
      newsArr: [],
      total: 20,
      start: 0,
      size: 10,
    }
  },
  components: {

  },
  methods: {
    init () {
      this.loadPageData(1); //加载第一页
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.loadPageData(val);
    },
    loadPageData (page) {
      var that = this;
      //获取中心动态新闻
      this.start = (page - 1) * this.size;
      article_list({ classId: 19, start: this.start, count: this.size }).then(res => {
        const { data } = res;
        console.log(data);
        that.newsArr = data.arr;
        that.total = parseInt(data.total);
      })
    }
  },
  mounted () {
    this.init();
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 1200px;
  min-height: 840px;
  margin: 60px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  .menu {
    // position: absolute;
    float: left;
    width: 320px;
    height: 200px;
    background: #fff;
    border-bottom: 1px solid #eeeeee;
    line-height: 35px;
    .menu-title {
      background: #409eff;
      padding: 30px 10px 34px 40px;
      text-align: left;
      p {
        font-size: 24px;
        color: #fff;
        margin: 0;
        padding: 0;
      }
    }
  }
  .right-content {
    float: left;
    width: 830px;
    background: #ffffff;
    padding: 0 14px;
    padding-top: 20px;

    .page {
      margin: 20px 0;
    }
    .content-list {
      .list-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #dbdbdb;
        padding: 26px 10px;
        text-align: left;
        cursor: pointer;
        .img-box {
          width: 250px;
          height: 155px;
          // margin-right: 38px;
          .imgCover {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .item-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 522px;
          .title {
            font-size: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            margin-bottom: 20px;
            width: 100%;
          }
          .time {
            color: #333;
            font: 14px/1 arial, '微软雅黑';
          }

          p {
            margin-top: 16px;
            color: #666;
            line-height: 1.5;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
